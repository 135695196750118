import { WithSidebarMenuLayout } from "components/routing/page/layouts/with-sidebar-menu/with-sidebar-menu-layout";
import MaintenanceTemplate from "pages/maintenance";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { LoginModal } from "templates/index/login";
import { useOngoingWalkthrough } from "logic/hooks/ongoing-walkthrough";
import { WorkspaceMode } from "store/modules/workspaces/types";
import { useImpersonateLogin } from "logic/hooks/impersonate-login";
import { Home } from "./components/home";
import { SignupRequiredAlert } from "../../../components/shared/signup-required-alert/signup-required-alert";
import { useHandleNewStudy } from "logic/hooks/handle-new-study";
import { getReleaseNotes } from "store/modules/release-notes/actions";

type IndexContainerProps = {
  isMaintenance: boolean;
  ragEnabled?: boolean;
  surveysEnabled?: boolean;
};

export const IndexContainer: React.FC<IndexContainerProps> = ({
  isMaintenance,
  ragEnabled,
  surveysEnabled,
}) => {
  function Content() {
    const dispatch = useAppDispatch();
    const workspaceMode = useAppSelector((state) => state.workspaces.data.workspaceMode);
    const releaseNotes = useAppSelector((state) => state.releaseNotes);

    const { ongoingWalkthrough, markWalkthroughAsDone } = useOngoingWalkthrough();
    const { isImpersonating } = useImpersonateLogin();

    const handleNewStudy = useHandleNewStudy();

    /***** Handle ongoing walkthrough *****/
    useEffect(() => {
      // Early return if no workspace mode
      if (!workspaceMode) return;
      // Mark walkthrough as done if not in Anonymous User mode
      if (workspaceMode !== WorkspaceMode.AnonymousUser && ongoingWalkthrough) {
        markWalkthroughAsDone();
      }
    }, [ongoingWalkthrough, markWalkthroughAsDone, workspaceMode]);

    useEffect(() => {
      // handle study reset when user navigates back to the home page through browser back button
      handleNewStudy();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!releaseNotes.data && !releaseNotes.loading && !releaseNotes.error) {
        dispatch(getReleaseNotes());
      }
    }, [releaseNotes, dispatch]);

    return (
      <WithSidebarMenuLayout
        ragEnabled={ragEnabled}
        surveysEnabled={surveysEnabled}
        isImpersonating={isImpersonating}
      >
        <Home />
      </WithSidebarMenuLayout>
    );
  }

  return (
    <>
      {isMaintenance ? (
        <MaintenanceTemplate />
      ) : (
        <SignupRequiredAlert>
          <Content />
          <LoginModal />
        </SignupRequiredAlert>
      )}
    </>
  );
};
